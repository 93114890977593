<template>
	<ZyroDrawer class="ecommerce-store-drawer">
		<p class="z-h5 ecommerce-store-drawer__title">
			{{ $t('builder.manageEcommerceStoreDrawer.title') }}
		</p>
		<div class="ecommerce-store-drawer__content">
			<img
				class="ecommerce-store-drawer__store-image"
				src="@/assets/images/store-manage.png"
			>
			<ul>
				<li
					v-for="(capability, index) in MANAGER_CAPABILITIES"
					:key="`${capability}${index}`"
					class="ecommerce-store-drawer__capability"
				>
					<span>
						{{ capability }}
					</span>
				</li>
			</ul>
			<ZyroButton
				v-qa="'drawer-manageecommerce-goto-manageecommercestore-btn'"
				class="ecommerce-store-drawer__button"
				theme="primary"
				@click="handleManageOpenStoreManager"
			>
				{{ $t('builder.manageEcommerceStoreDrawer.button') }}
			</ZyroButton>
		</div>
	</ZyroDrawer>
</template>

<script>

import {
	mapActions,
	mapGetters,
	mapState,
} from 'vuex';

import i18n from '@/i18n/setup';
import { STORE_MANAGER_ROUTE } from '@/router';
import { EDIT_ONLINE_STORE_MODAL } from '@/store/builder/constants/modals';
import {
	CLOSE_DRAWER,
	mapActionsGui,
	OPEN_MODAL,
} from '@/store/builder/gui';

const MANAGER_CAPABILITIES = [
	i18n.t('builder.manageEcommerceStoreDrawer.capability1'),
	i18n.t('builder.manageEcommerceStoreDrawer.capability2'),
	i18n.t('builder.manageEcommerceStoreDrawer.capability3'),
];

export default {
	setup() {
		return { MANAGER_CAPABILITIES };
	},
	computed: {
		...mapGetters('subscription', ['hasEcommercePlan']),
		...mapState('user', ['isUserDesigner']),
	},
	methods: {
		...mapActions('pages', ['addPage']),
		...mapActionsGui({
			closeDrawer: CLOSE_DRAWER,
			openModal: OPEN_MODAL,
		}),
		handleManageOpenStoreManager() {
			this.closeDrawer();

			if (!this.hasEcommercePlan) {
				this.openModal({ name: EDIT_ONLINE_STORE_MODAL });
			}

			if (this.hasEcommercePlan || this.isUserDesigner) {
				this.$router.push({ name: STORE_MANAGER_ROUTE });
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.ecommerce-store-drawer {
	padding: 24px;

	&__content {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 64px 58px;
	}

	&__store-image {
		width: 250px;
		height: 170px;
		margin-bottom: 32px;
		object-fit: cover;
	}

	&__page-title {
		margin-bottom: 64px;
	}

	&__page-subtitle {
		margin-bottom: 24px;
	}

	&__button {
		min-width: 220px;
		margin-top: 32px;
	}

	&__capability {
		margin-bottom: 8px;
	}
}
</style>
